

$charcoal:                     #333333;
$darkgrey:                     #5A5A5A;
$grey:                         #858585;
$midgrey:                      #B6B6B6;
$lightgrey:                    #B6B6B6;
$palegrey:                     #E6E6E6;
$whitesmoke:                   #F5F5F5;
$silver:                       #F9F9F9;

$darkblue:                     #1A237E;
$midblue:                      #3F51B5;
$lightblue:                    #E8EAF6;
$altblue:                      #03A9F4;

$apple:                        #20B24B;
$lime:                         #87C540;
$red:                          #EE3024;
$slate:                        #1C7ABF;
$teal:                         #03B2D6;
$navy:                         #2E439B;
$purple:                       #804299;
$cerise:                       #ED2C92;
$orange:                       #F47D20;
$yellow:                       #F8E71C;

$darkgreen:                    #2B4F24;
$darkgreenoff:                 #55874A;


$body-font-color:              $charcoal;
$link-color:                   $midblue;
$link-color-hover:             $altblue;

$shadow-color:                 $lightgrey;
$base-border-color:            $lightgrey;


// Top bar and user menu
$top-bar-color:                $darkgrey;
$user-menu-link-color:         white;
$user-menu-hover-color:        white;

// User bar and main menu
$main-menu-wrapper-color:      $yellow;
$main-menu-link-color:         $darkgreen;
$main-menu-hover-color:        $darkgreen;
$main-menu-hover-link-color:   $yellow;
$mobile-menu-bg-color:         $darkgreen;
$hamburger-color:              white;


// Page body
$body-bg-color:                white;
$hero-text-color:              white;

// Background fills
$header-color:                 $darkgreen;
$featured-bg-color:            white;
$preface-bg-color:             white;
$well-bg:                      whitesmoke;

// Blocks
$block-style-1-header-bg:      $darkgreenoff;
$block-style-2-header-bg:      $darkgreenoff;
$block-style-1-header-color:   white;
$block-style-2-header-color:   white;
$block-style-1-bg:             white;
$block-style-2-bg:             white;
$section-box-bg-color:         whitesmoke;

// Footer
$footer-bg-color:              $yellow;



.solid-color-1 {
  background: $darkgreen;
}
.solid-color-2 {
  background: $midblue;
}
.solid-color-3 {
  background: $lightblue;
}
.header-wrapper {
  @include linear-gradient($darkblue, $midblue);
}

