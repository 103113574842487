.header-wrapper {
  @include linear-gradient($darkgreen, $darkgreenoff);
}


.header-contact-details {
  color: white;
  letter-spacing: 0.08em;
  strong {
    color: $yellow;
  }
  h4 {
    line-height: 1.4em;
    margin: 0;
  }
}

.navigation-wrapper {
  border-bottom: 1px solid $darkgreen;
}

.front-page-hero {
  p {
    margin: 0;
  }
  h2 {
    background: rgba(0,0,0,0.5);
    @include media($mobile) {
      width: 100%;
      font-size: 1.4em !important;
    }
    @include media($tablet) {
      width: 100%;
      font-size: 2.5em !important;
    }
    @include media($laptop) {
      width: 70%;
      font-size: 2.5em !important;
    }
    @include media($desktop) {
      width: 70%;
      font-size: 3em !important;
    }
    @include media($highdef) {
      width: 85%;
      font-size: 4em !important;
    }
  }
  ul{
    background: rgba(0,0,0,0.5);
    display: inline-block;
    //display: none;
    //font-weight: 700;
    color: $yellow;
    padding: 0 10px;
    margin-top: 10px!important;;
    @include media($mobile) {
      font-size: 1.2em;
      min-width: 215px;
    }
    @include media($desktop) {
      width: 330px;
      font-size: 1.8em !important;
      margin-left: 20%;
    }
  }
  li {
    margin: 0!important;
  }
}

.front-page-intro h2 {
  color: $darkgreen !important;
}


.front {
  #row1 {
    padding:  50px 14px;
    @include media($laptop) {
      padding:  120px 14px 110px;
    }
    background-image: url("../images/bg-yellow-bolts.jpg");
    background-position: center;
    color: $darkgreen;
    h2 {
      @include span-columns(12);
      text-align: center;
      display: inline-block;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .field-name-field-block-text   {
        text-align: center;
      @include span-columns(12);
      a {
        @include button-style-1;
      }
    }
    @include media($laptop) {
      h2 {
        @include span-columns(8);
        text-align: center;
        padding: 0 5%;
      }
      .field-name-field-block-text  {
        @include span-columns(4);
        display: flex;
        align-items: center;
        justify-content: center;
        a {
          @include button-style-1;
          font-size: 1.5em;
          margin-top: 35px;
        }
      }
    }
  }
  #row2 {
    padding: 70px 14px;
    background: white;
    min-height: 300px;
    p {
      font-size: 19px;
    }
    img[style*="right"] {
      margin: 8px 0 10px 40px;
    }
  }
  #row3 {
    padding:  50px 14px;
    @include media($laptop) {
      padding:  120px 14px 110px;
    }
    background-image: url("../images/bg-green-bolts.jpg");
    background-position: center;
    color: $yellow;
    h2 {
      @include span-columns(12);
      text-align: center;
      display: inline-block;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .field-name-field-block-text   {
        text-align: center;
      @include span-columns(12);
      a {
        @include button-style-1;
      }
    }
    @include media($laptop) {
      h2 {
        @include span-columns(8);
        text-align: center;
        padding: 0 5%;
      }
      .field-name-field-block-text  {
        @include span-columns(4);
        display: flex;
        align-items: center;
        justify-content: center;
        a {
          @include button-style-1;
          font-size: 1.5em;
          margin-top: 35px;
        }
      }
    }

  }
  #row4 {
    padding: 70px 14px;
    background: white;
    p {
      font-size: 19px;
    }
    img[style*="left"] {
      margin: 8px 40px 10px 0;
    }
  }
}



.bold-header h2 {
  font-weight: 700;
  font-size: 1.562em;
  @include media($laptop) {
    font-size: 2em;
  }
  @include media($desktop) {
    font-size: 2.929em;
  }
}

.phone-icon {
  display: block;
  position: fixed;
  bottom: 5px;
  right: 11px;
  z-index: 9999;
  @include media($laptop) {
    display: none;
  }
}

#site-footer {
  position: relative;
  .easable-credit {
    @include media($laptop) {
      position: absolute;
      bottom: 10px;
      right: 0px;
    }
  }
}




